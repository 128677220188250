.card{
  height:100%;
  max-height:450px ;
}
@media screen and  (max-width:1250px){
  *{
    font-size:15px;
  }
  

}
@media screen and  (max-width:650px){
  *{
    font-size:12px;
  }
  

}